import React from "react";
import ReactDOM from "react-dom";
import Application from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <BrowserRouter><Application /></BrowserRouter>,
  document.getElementById("root")
);
