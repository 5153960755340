import React, {useEffect, useState} from "react";
import http, {API_BASE_URL} from '../http/http';
import {Dropdown, Spinner, Table} from "react-bootstrap";
import {Doughnut, Line} from 'react-chartjs-2';

const PerformanceRun = () => {
    const [performanceRuns, setPerformanceRuns] = useState([]);
    const [spinnerVisibility, setSpinnerVisibility] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [dropdownTitle, setDropdownTitle] = useState('Data');
    const [labels, setLabels] = useState([]);
    const [httpData, setHttpData] = useState([]);
    const [tlsData, setTlsData] = useState([]);
    const [mtlsData, setMtlsData] = useState([]);
    const [httpDataAverage, setHttpDataAverage] = useState(0);
    const [tlsDataAverage, setTlsDataAverage] = useState(0);
    const [mtlsDataAverage, setMtlsDataAverage] = useState(0);
    const [lineData] = useState({
        labels,
        datasets: [
            {
                label: 'Http Only',
                fill: true,
                data: httpData,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'TLS',
                fill: true,
                data: tlsData,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'M_TLS',
                fill: true,
                data: mtlsData,
                backgroundColor: 'rgba(186,53,235,0.5)',
            },
        ],
    });

    const lineOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'TLS Handshake',
            },
        },
    };

    const data = {
        labels: [
            'Http Average',
            'TLS Average',
            'M_TLS Average'
        ],
        datasets: [{
            label: 'Average',
            data: [httpDataAverage, tlsDataAverage, mtlsDataAverage],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)'
            ],
            hoverOffset: 4
        }]
    };


    useEffect(() => {
        setSpinnerVisibility(true);
        fetchData().then((data) => {
            setPerformanceRuns(data);
            data.map((run) => {
                setLabels(labels.push(run.created_date));
                setHttpData(httpData.push(run.http_total_connect_time));
                setTlsData(tlsData.push(run.tls_total_connect_time));
                setMtlsData(mtlsData.push(run.m_tls_total_connect_time));
            })
        }).then(() => {
            let total = 0;
            httpData.forEach((data) => {
                total += parseFloat(data);
            });
            let average = parseFloat((total / httpData.length).toFixed(3));
            setHttpDataAverage(average);
            total = 0;
            tlsData.forEach((data) => total += parseFloat(data));
            average = parseFloat((total / tlsData.length).toFixed(3));
            setTlsDataAverage(average);

            total = 0;
            mtlsData.forEach((data) => total += parseFloat(data));
            average = parseFloat((total / mtlsData.length).toFixed(3));
            setMtlsDataAverage(average);

        }).then(() => {
            setSpinnerVisibility(false);
        })
    }, [])

    return (
        <div style={{paddingBottom: "50px"}}>
            <Dropdown style={{paddingBottom: "10px"}}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {dropdownTitle}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item active={!showChart} onClick={(event) => {
                        setShowChart(false);
                        setDropdownTitle('Data');
                    }}> Data </Dropdown.Item>
                    <Dropdown.Item active={showChart} onClick={(event) => {
                        setShowChart(true);
                        setDropdownTitle('Graph');
                    }}> Graph </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {!showChart ?
                <div>
                    {spinnerVisibility ? <div style={{textAlign: "center"}}><Spinner animation="border"/></div> :
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Created Date</th>
                                <th>TLS Handshake</th>
                                <th>TLS Total</th>
                                <th>M_TLS Handshake</th>
                                <th>M_TLS Total</th>
                                <th>HTTP Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {performanceRuns.map((run) => {
                                return <tr key={run.id}>
                                    <td>{run.id}</td>
                                    <td>{run.created_date}</td>
                                    <td>{run.tls_handshake_time}</td>
                                    <td>{run.tls_total_connect_time}</td>
                                    <td>{run.m_tls_handshake_time}</td>
                                    <td>{run.m_tls_total_connect_time}</td>
                                    <td>{run.http_total_connect_time}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>}
                </div> : (
                    <div>
                         <div style={{
                            paddingTop: "30px",
                        }}>
                            <div style={{paddingBottom: "20px", fontSize: "20px", textAlign: "center"}}>
                                Connection Time (milliseconds)
                            </div>
                            <Line data={lineData} options={lineOptions} height={50}/>
                        </div>
                        <div style={{
                            paddingTop: "40px",
                            marginBottom: "40px",
                            width: "400px",
                            height: "300px",
                            margin: "auto",
                            fontSize: "20px",
                            minHeight: "100px"
                        }}>
                            <div style={{paddingBottom: "20px"}}>
                                Average Connection Time (milliseconds)
                            </div>
                            <Doughnut data={data} height={10} width={10}/>
                        </div>
                    </div>)
            }
        </div>
    );
};


const fetchData = async () => {
    return http
        .get(API_BASE_URL)
        .then((response) => {
            let data = response.data;
            return Promise.resolve(data);
        })
        .catch((e) => {
            console.error("failed to get tls performance run data", e);
            return Promise.reject(e);
        });
};


export default PerformanceRun;