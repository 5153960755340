import React from "react";
import Nav from 'react-bootstrap/Nav';
import PerformanceRun from "./PerformanceRun";
import Encryption from "./Encryption";
import Digest from "./Digest";
import Decryption from "./Decryption";

const Landing = () => {

    const [showPerformance, setShowPerformance] = React.useState(true)
    const [showEncryption, setShowEncryption] = React.useState(false)
    const [showDecryption, setShowDecryption] = React.useState(false)
    const [showDigest, setShowDigest] = React.useState(false)

    return (
        <div style={{padding: "20px"}}>
            <Nav fill variant="tabs"
                 onSelect={(selectedKey) => {
                     if (selectedKey === 'perf') {
                         setShowPerformance(true);
                         setShowEncryption(false);
                         setShowDecryption(false);
                         setShowDigest(false);
                     }
                     if (selectedKey === 'enc') {
                         setShowPerformance(false);
                         setShowEncryption(true);
                         setShowDecryption(false);
                         setShowDigest(false);
                     }
                     if (selectedKey === 'dec') {
                         setShowPerformance(false);
                         setShowEncryption(false);
                         setShowDecryption(true);
                         setShowDigest(false);
                     }
                     if (selectedKey === 'dig') {
                         setShowPerformance(false);
                         setShowEncryption(false);
                         setShowDecryption(false);
                         setShowDigest(true);
                     }
                 }}
            >
                <Nav.Item>
                    <Nav.Link eventKey="perf" >Performance Runs</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="enc">Encryption</Nav.Link>
                </Nav.Item>
                 <Nav.Item>
                    <Nav.Link eventKey="dec">Decryption</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="dig">Digests</Nav.Link>
                </Nav.Item>
            </Nav>

            <div style={{paddingTop: "50px"}}>
                {showPerformance ? <PerformanceRun></PerformanceRun> : null}
                {showEncryption ? <Encryption></Encryption> : null}
                {showDecryption ? <Decryption></Decryption> : null}
                {showDigest ? <Digest></Digest> : null}
            </div>
        </div>
    );
};


export default Landing;