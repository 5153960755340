import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import sha1 from 'crypto-js/sha1';
import hmac from 'crypto-js/hmac-sha512';
import md5 from 'crypto-js/md5';

const Digest = () => {
    const [state, setState] = useState({
        text: "",
        alg: "MD5",
        hashText: ""
    });

    function computeHash() {
        let hash = md5(state.text);
        if (state.alg === 'SHA1') {
            hash = sha1(state.text);
        }
        else if (state.alg === 'HMAC') {
            hash = hmac(state.text, "Secret Passphrase");
        }
        setState({text: state.text, alg: state.alg, hashText: hash});
    }

    return (
        <div style={{textAlign: "left"}}>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicPlaintext">
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>Hash Text</Form.Label>
                        <Form.Control as="textarea" rows={3} value={state.text}
                                      onChange={e => setState({text: e.target.value, alg: state.alg, hashText: state.hashText})}/>
                    </div>
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>Hash</Form.Label>
                        <Form.Select aria-label="Select Algorithm" value={state.alg}
                                      onChange={e => setState({text: state.text, alg: e.target.value, hashText: state.hashText})}>
                            <option value="MD5">MD5</option>
                            <option value="SHA1">SHA1</option>
                            <option value="HMAC">HMAC</option>
                        </Form.Select>
                    </div>
                </Form.Group>
                <div style={{paddingBottom: "10px"}}>
                    <Button variant="primary" onClick={() => computeHash()}>
                        Compute
                    </Button>
                </div>
                <Form.Group className="mb-3" controlId="formBasicHashText">
                    <Form.Label>Hash</Form.Label>
                    <Form.Control as="textarea" rows={3} disabled={true} value={state.hashText}/>
                </Form.Group>
            </Form>
        </div>
    );
};


export default Digest;