import React from "react";
import {Route, Switch} from "react-router";
import Landing from "./views/Landing";

const Application = () => {
    return (<>
        <div>
            <Switch>
                <Route key="Landing" path="/" exact render={() => <Landing/>}/>
            </Switch>
        </div>
    </>);
};

export default Application;
