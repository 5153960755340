import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import CryptoJS from "crypto-js";

const Decryption = () => {

    const [state, setState] = useState({
        text: "",
        cipherText: "",
        cipher: "AES",
        secret: ""
    });

    function decrypt() {
        setState({text: "", secret: state.secret, cipherText: state.cipherText, cipher: state.cipher});
        let plainText = CryptoJS.AES.decrypt(state.cipherText, state.secret).toString(CryptoJS.enc.Utf8);
        if (state.cipher === 'RABBIT') {
            plainText = CryptoJS.Rabbit.decrypt(state.cipherText, state.secret).toString(CryptoJS.enc.Utf8);
        } else if (state.cipher === 'RC4') {
            plainText = CryptoJS.RC4.decrypt(state.cipherText, state.secret).toString(CryptoJS.enc.Utf8);
        } else if (state.cipher === 'TRIPLEDES') {
            plainText = CryptoJS.DES.decrypt(state.cipherText, state.secret).toString(CryptoJS.enc.Utf8);
        }
        setState({text: plainText, secret: state.secret, cipherText: state.cipherText, cipher: state.cipher});
    }

    return (
        <div style={{textAlign: "left"}}>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicPlaintext">
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>Algorithm</Form.Label>
                        <Form.Select aria-label="Select Cipher" value={state.cipher}
                                     onChange={e => setState({text: state.text, secret: state.secret, cipherText: state.cipherText, cipher: e.target.value})}>
                            <option value="AES">AES</option>
                            <option value="RABBIT">RABBIT</option>
                            <option value="TRIPLEDES">TRIPLEDES</option>
                            <option value="RC4">RC4</option>
                        </Form.Select>
                    </div>
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>Secret Key</Form.Label>
                        <Form.Control value={state.secret} onChange={e => setState({text: state.text, secret: e.target.value, cipherText: state.cipherText, cipher: state.cipher})}/>
                    </div>
                   <Form.Label>CipherText</Form.Label>
                    <Form.Control as="textarea" rows={3} value={state.cipherText} onChange={e => setState({text: state.text, secret: state.secret, cipherText: e.target.value, cipher: state.cipher})}/>
                </Form.Group>
                <div style={{paddingBottom: "10px"}}>
                    <Button variant="primary" onClick={() => decrypt()}>
                        Decrypt
                    </Button>
                </div>
                <Form.Group className="mb-3" controlId="formBasicCiphertext">
                     <div style={{paddingBottom: "10px"}}>
                        <Form.Label>PlainText</Form.Label>
                        <Form.Control as="textarea" rows={3} disabled={true} value={state.text}/>
                    </div>
                </Form.Group>
            </Form>
        </div>
    );
};


export default Decryption;