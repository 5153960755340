import axios from "axios";
import * as https from "https";

const http = {
    async get(url) {
        return await axios.get(url, {
            headers: {
                "Content-Type": "application/json",
            },
            httpsAgent: new https.Agent({rejectUnauthorized: false}),
        }).then((data) => {
            if (data === undefined) {
                throw new Error("Offline");
            } else if (data.status < 200 || data.status > 299) {
                throw new Error(
                    `Unable to fetch data, status code: ${data.status}`
                );
            }
            return Promise.resolve(data);
        }).catch((error) => {
            console.error("Error performing http get:", error.toJSON());
            return Promise.reject(error);
        });
    },
};

export const API_BASE_URL = `https://api.barryauhl.com.au/zzen9203-data`;
export default http;