import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import CryptoJS from "crypto-js";

const Encryption = () => {

    const [state, setState] = useState({
        text: "",
        cipherText: "",
        cipher: "AES",
        secret: ""
    });

    function computeCipher() {
        setState({text: state.text, secret: state.secret, cipherText: "", cipher: state.cipher});
        let cipherText = CryptoJS.AES.encrypt(state.text, state.secret).toString();
        if (state.cipher === 'RABBIT') {
            cipherText = CryptoJS.Rabbit.encrypt(state.text, state.secret).toString();
        } else if (state.cipher === 'RC4') {
            cipherText = CryptoJS.RC4.encrypt(state.text, state.secret).toString();
        } else if (state.cipher === 'TRIPLEDES') {
            cipherText = CryptoJS.DES.encrypt(state.text, state.secret).toString();
        }
        setState({text: state.text, secret: state.secret, cipherText: cipherText, cipher: state.cipher});
    }

    return (
        <div style={{textAlign: "left"}}>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicPlaintext">
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>Algorithm</Form.Label>
                        <Form.Select aria-label="Select Cipher" value={state.cipher}
                                     onChange={e => setState({text: state.text, secret: state.secret, cipherText: state.cipherText, cipher: e.target.value})}>
                            <option value="AES">AES</option>
                            <option value="RABBIT">RABBIT</option>
                            <option value="TRIPLEDES">TRIPLEDES</option>
                            <option value="RC4">RC4</option>
                        </Form.Select>
                    </div>
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>Secret Key</Form.Label>
                        <Form.Control value={state.secret} onChange={e => setState({text: state.text, secret: e.target.value, cipherText: state.cipherText, cipher: state.cipher})}/>
                    </div>
                    <div style={{paddingBottom: "10px"}}>
                        <Form.Label>PlainText</Form.Label>
                        <Form.Control as="textarea" rows={3} value={state.text} onChange={e => setState({text: e.target.value, secret: state.secret, cipherText: state.cipherText, cipher: state.cipher})}/>
                    </div>
                </Form.Group>
                <div style={{paddingBottom: "10px"}}>
                    <Button variant="primary" onClick={() => computeCipher()}>
                        Encrypt
                    </Button>
                </div>
                <Form.Group className="mb-3" controlId="formBasicCiphertext">
                    <Form.Label>CipherText</Form.Label>
                    <Form.Control as="textarea" rows={3} disabled={true} value={state.cipherText}/>
                </Form.Group>
            </Form>
        </div>
    );
};


export default Encryption;